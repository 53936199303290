<template>
  <a-upload
    name="file"
    accept="image/*"
    :maxCount="1"
    action="/admin/upload/img"
    :list-type="listType"
    :show-upload-list="false"
    @change="handleUploadChange"
  >
    <img v-if="coverSrc" :src="coverSrc" alt="avatar" class="cover_img" />
    <div v-else>
      <loading-outlined style="font-size: 30px" v-if="uploadLoading"></loading-outlined>
      <plus-outlined style="font-size: 30px" v-else></plus-outlined>
    </div>
  </a-upload>
</template>

<script setup>
import { computed, ref } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
const props = defineProps({
  cover: {
    type: String,
    default: '',
  },
  listType: {
    type: String,
    default: 'picture-card',
  },
})
const emit = defineEmits(['uploadSuccess'])
const uploadLoading = ref(false)
// 图片地址
const coverSrc = computed(() => props.cover || '')

const handleUploadChange = (info) => {
  console.log('info---', info)
  if (info.file.status === 'uploading') {
    uploadLoading.value = true
    return
  }
  if (info.file.status === 'done') {
    if (info.file.response && info.file.response.status === 0) {
      const url = info.file.response.data
      // 截取图片格式前名字
      const name = info.file.name
      // 定义正则表达式来匹配多种图片格式后缀
      let imageRegex = /\.(jpg|jpeg|png|gif)$/
      let match = name.match(imageRegex)
      let fileName
      if (match && match[0]) {
        // 从匹配结果中获取文件名
        fileName = name.split(match[0])[0]
      } else {
        // 如果匹配不到后缀，则提取整个URL作为文件名
        fileName = name
      }
      emit('uploadSuccess', url, fileName)
    }
    uploadLoading.value = false
  }
  if (info.file.status === 'error') {
    uploadLoading.value = false
    message.error('上传失败')
  }
}
</script>

<style>
.cover_img {
  width: 100px;
  height: 100px;
  display: inline-block;
}
</style>
