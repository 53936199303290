import axios from 'axios'
import { notification } from 'ant-design-vue'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 90000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    const header = {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest',
    }
    if (['get', 'GET', 'delete', 'DELETE'].includes(config.method)) {
      header['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
      config.params = config.data
      delete config.data
    }
    config.headers = {
      ...config.headers,
      ...header,
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    const { status } = res
    // 失败
    if (status === undefined || status === null) {
      notification.error({
        message: '错误',
        description: res.message || '服务端错误',
      })
      return Promise.reject(res || 'Error')
    }
    // 成功
    if (status === 0) {
      return res
    }
    // 没权限
    // 40100没登录（须确认）
    if ([40100, 40300].includes(status)) {
      notification.warning({
        message: '提示',
        description: res.message || '暂无权限',
      })
      return res
    }
    notification.error({
      message: '错误',
      description: res.message || '请求失败，请稍后再试',
    })
    return Promise.reject(res || 'Error')
  },
  (error) => {
    const errStr = 'err' + error
    if (errStr && errStr.indexOf('timeout') > -1) {
      //超时处理
      notification.error({
        message: '错误',
        description: '请求超时，请重试',
      })
    } else {
      notification.error({
        message: '错误',
        description: error.message || '请求失败，请稍后再试',
      })
    }
    return Promise.reject(error)
  }
)

export default service
