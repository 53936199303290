<template>
  <a-layout style="min-height: 100vh">
    <sidebar />
    <a-layout>
      <header-bar />
      <app-main />
    </a-layout>
  </a-layout>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import Sidebar from './components/Sidebar'
import HeaderBar from './components/HeaderBar'
import AppMain from './components/AppMain'

export default defineComponent({
  components: {
    Sidebar,
    HeaderBar,
    AppMain,
  },
  setup() {
    const store = useStore()
    onMounted(() => {
      // 获取省市区数据
      store.dispatch('global/Get_Region')
      // 获取所有分类数据
      store.dispatch('global/Get_Category_All')
      // 获取所有角色数据
      store.dispatch('global/Get_Role_All')
      // 获取所有仓库数据
      store.dispatch('global/Get_Stock_All')
    })
  },
})
</script>

<style lang="scss" scoped></style>
